.logo {
  margin-right: 20px;
  width: 65px;

  @include respond-down(M) {
    width: 95px;
  }

  &.colored {
    svg {
      path {
        &.R {
          fill: #ee3927;
        }
        &.G {
          fill: #444a4b;
        }
      }
    }
  }

  svg {
    overflow: visible;
    path {
      fill: #fff;
      transition: fill 0.3s ease;
    }
    .rot {
      transform: rotate(0deg);
      transform-origin: 12px 11.5px;
      transition: transform 0.2s ease;
    }
  }

  &:hover {
    svg {
      .rot {
        transform: rotate(360deg);
      }
      path {
        &.R {
          fill: #ee3927;
        }
        &.G {
          //fill: #444a4b;
        }
      }
    }
  }
}

.theme-color {
  fill: #cc1200;
  fill: #ee3927;
  transition: fill 0.15s linear;
}

.menu-main {
  width: 100%;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: transparent;
  min-height: 0.001vh;

  .head {
    width: 100%;
    height: 40px;
    top: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 450;

    @include respond-down(M) {
      top: 10px;
    }

    .phone {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      @include respond-down(M) {
        margin-left: auto;
        margin-right: 7px;
      }

      .top-phone {
        font-size: 16px;
        font-weight: 700;
        margin-left: 25px;
        color: #fff;

        @include respond-down(M) {
          display: none;
        }
      }

      .client.btn {
        width: 50px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 10px;
        svg {
          width: 24px;
          path {
            fill: #fff;
          }
        }
      }
    }

    .btn {
      @include respond-down(M) {
        border-radius: 8px;
        min-width: 0;
        padding: 0 6px;
        height: 38px;
        font-size: 14px;
      }
    }

    .menu-items-top {
      display: flex;

      @include respond-down(M) {
        display: none;
      }

      .menu-item {
        list-style: none;
        font-weight: 700;
        font-size: 16px;
        font-optical-sizing: auto;
        font-weight: 700;
        font-style: normal;
        margin: 0 7px;
        //height: 92px;
        display: flex;
        align-items: center;

        @include respond-down(L) {
          font-size: 14px;
          margin: 0 4px;
        }
        @include respond-down(M) {
          height: 54px;
        }

        .sub-menu {
          position: absolute;
          display: flex;
          flex-direction: column;
          padding: 8px 0;
          top: 40px;
          border-radius: 8px;
          background: #444a4b;
          border-radius: 8px;
          transition:
            opacity 0.2s ease,
            margin-left 0.2s ease;
          margin-left: -20px;
          opacity: 0;

          a {
            padding: 8px 24px;
            font-weight: 400;
          }
        }

        &:hover {
          .sub-menu {
            opacity: 1;
            margin-left: -10px;
          }
        }

        a {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          padding: 10px;
          color: #fff;
          transition: color 0.15s linear;
          margin: 2px;

          &.nohover {
            cursor: default !important;
          }

          &.current {
            color: #ee3927;
          }

          &:hover:not(&.nohover) {
            color: #ee3927;
          }
        }
      }
    }

    .opener {
      display: none;

      @include respond-down(M) {
        display: block;
      }

      .hamburger {
        margin-left: 10px;
        width: 22px;
        height: 20px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        padding: 2px 2px;
        cursor: pointer;
        align-items: flex-end;

        .line {
          height: 2px;
          background: rgba(217, 217, 217, 1);
          width: 100%;
          transition: width 0.1s linear;
        }
        .line:nth-child(2) {
          width: 70%;
        }

        &:hover {
          @include respond-up(M) {
            .line:nth-child(2) {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

.body-wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;

  &.white-style {
    .menu-main .head {
      .menu-items-top > .menu-item > a {
        color: #3d3d3d;
        &.current {
          color: #ee3927;
        }
      }
      .top-phone {
        color: #3d3d3d;
      }
      .opener .hamburger .line {
        background: #3d3d3d;
      }
    }
    .logo svg path {
      &.R {
        fill: #ee3927;
      }
      &.G {
        fill: #444a4b;
      }
    }
  }
}

.mobile-menu {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: auto;
  background: #3d3d3d;
  z-index: 800;
  border-radius: 16px 0 0 16px;
  padding-bottom: 50px;
  display: none;
  transition:
    left 0.2s ease,
    opacity 0.2s ease;

  @include respond-down(M) {
    display: block;
  }

  &.opened {
    opacity: 1;
    left: 0;
  }

  .phone-top {
    height: 48px;
    display: flex;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;

    .phone {
      color: #ee3927;
      font-size: 24px;
      font-weight: 700;
      padding-left: 32px;
    }
    .close {
      padding: 17px;
    }
  }

  .menu-item {
    margin-bottom: 20px;
    list-style: none;
    display: block;

    .link {
      font-weight: 700;
      font-size: 24px;
      color: #fff;
      padding: 6px 0;
      padding-left: 32px;

      &.nohover {
        color: #aaa;
      }
      &.current {
        color: #ee3927;
      }
    }

    .sub-menu {
      padding-top: 16px;
      margin-left: 32px;
      padding-left: 18px;
      display: flex;
      flex-direction: column;

      a {
        color: #ececec;
        font-size: 16px;
        margin-bottom: 16px;
        padding-top: 4px;
        font-weight: 500;

        &.current {
          color: #ee3927;
        }
      }
    }
  }
}

.video-bg {
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: opacity 0.25s ease;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    transform: rotate(0.001deg) translate3d(-50%, -50%, 0);
    perspective: 1000px;
    background: #000;
  }
}
