$col_00: #0a1f25;
$col_10: #2d2d2d;
$col_20: #43494a;
$col_21: rgba(67, 73, 74, 0.31);
$col_22: rgba(67, 73, 74, 0.1);
$col_30: #707070;
$col_31: rgba(112, 112, 112, 0.2);
$col_40: #696d6e;

$col_45: #c6c6c6;
$col_50: #c6c8c8;
$col_60: #d9dbdb;
$col_65: #eeeeee;
$col_67: #efecec;
$col_70: #ebebeb;
$col_80: #f6e726;
$col_90: #ffffff;
$col_100: #f7f7f7;
