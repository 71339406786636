.about-points {
  background: linear-gradient(87.13deg, #ffffff 6.43%, #e0e0e0 87.89%);
  padding: 140px 0 140px 0;
  position: relative;

  @include respond-down(M) {
    padding: 24px 0 40px 0;
  }

  &:after {
    content: "";
    position: absolute;

    width: 900px;
    height: 900px;
    background: no-repeat center center;
    background-image: url("/static/images/ellipse.png");

    left: 440px;
    bottom: 450px;
    transform: rotate(-33.04deg);

    @include respond-down(M) {
      left: -420px;
      bottom: 120px;
    }
  }

  .logo {
    margin-bottom: 20px;
    @include respond-down(M) {
      margin-right: 0;
      margin-bottom: 10px;
    }

    svg {
      width: 134px;

      @include respond-down(M) {
        width: 100px;
      }
    }
  }
}

.wrapper-red-items {
  position: relative;
  z-index: 20;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  @include respond-down(L) {
    column-gap: 16px;
    row-gap: 16px;
  }
  @include respond-down(M) {
    display: flex;
    flex-direction: column;
    column-gap: 8px;
    row-gap: 8px;
  }

  .item {
    color: #3d3d3d;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    padding: 24px;
    line-height: 1.2em;
    border-radius: 28px;
    min-height: 213px;
    background: #fff;
    position: relative;
    overflow: hidden;

    @include respond-down(M) {
      border-radius: 28px;
      min-height: 136px;
      font-size: 18px;
      font-weight: 700;
    }

    &.red {
      background: #ee3927;
      color: #fff;
    }

    &.gray {
      background: #f4f4f4;
      .roic {
        background: #fff;
      }
    }
    &.grayplus {
      background: #e3e3e3;
      .roic {
        background: #fff;
      }
    }

    p {
      position: relative;
      z-index: 30;

      .norm {
        font-weight: 400;
        font-size: 90%;
      }
    }

    span {
      //  font-weight: 400;
    }

    .bg {
      position: absolute;
      left: 0px;
      bottom: 0px;
      z-index: 10;

      &.bg-07 {
        left: 18px;
        bottom: 12px;
      }
    }
  }
}

.our-mission {
  padding-top: 56px;
  position: relative;
  margin-bottom: 80px;

  @include respond-down(M) {
    padding-top: 46px;
    height: 400px;
  }

  .text-alerts {
    position: absolute;
    left: 50%;
    top: 44%;
    width: 790px;
    margin-left: -395px;
    text-align: center;

    @include respond-down(M) {
      width: 300px;
      margin-left: -150px;
    }
  }

  .mission-bg {
    position: relative;
    z-index: 10;

    @include respond-down(M) {
      position: absolute;
      width: 750px;
      left: 50%;
      margin-left: -310px;
      top: 45px;
    }
  }
}

.text-alerts {
  z-index: 20;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%; /* 55.2px */
  color: #3d3d3d;

  @include respond-down(M) {
    font-size: 24px;
  }

  b {
    color: #ee3927;
  }
}

.costs {
  border-radius: 28px;
  background: #ee3927;
  padding: 70px 100px;
  position: relative;

  @include respond-down(M) {
    border-radius: 0;
    padding: 24px 16px;
  }

  & > svg {
    position: absolute;
    z-index: 1;
    width: 870px;
    right: -20px;
    top: 0;

    @include respond-down(M) {
      right: -610px;
    }
  }

  .f-items {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    z-index: 50;
    position: relative;
  }

  .slide {
    background: #f4f4f4;
    border-radius: 28px;
    min-height: 213px;
    padding: 24px;
    flex: 1 1 33.333%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #ee3927;
      border-bottom: 1px solid #ee3927;
      margin-bottom: 16px;
      padding-bottom: 4px;

      @include respond-down(M) {
        font-size: 13px;
        margin-bottom: 8px;
        padding-bottom: 2px;
      }
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 0.8em;

      &:last-child {
        padding-bottom: none;
      }
    }
  }
}

.costs {
  .slick-list {
    margin: 0 -82px;
    padding: 0 82px !important;
    position: relative;

    &:before,
    &:after {
      content: "";
      z-index: 50;
      position: absolute;
      height: 100%;
      width: 82px;
      background: linear-gradient(90deg, rgba(238, 57, 39, 0) 0%, #ee3927 100%);
      right: 0;
      top: 0;
    }
    &:before {
      background: linear-gradient(90deg, #ee3927 0%, rgba(0, 0, 0, 0) 100%);
      right: auto;
      left: 0;
    }
  }
  .slick-slide {
    width: 360px;
    margin-right: 24px;
  }
  .f-items {
    @include respond-down(M) {
      flex-direction: column;
    }

    .slide {
      width: 360px;
      margin-right: 24px;
      position: relative;
      padding-left: 110px;
      flex: 0 0 360px;
      background: #f4f4f4;
      height: 220px;
      overflow: hidden;

      @include respond-down(M) {
        margin-right: 0;
        min-height: 0;
        padding: 16px 20px;
        font-size: 14px;
        flex: 1 1 100%;
        width: 100%;
      }

      p {
        font-size: 14px !important;
        line-height: 1.3em;
      }
      .roic {
        background: #fff;
        top: 24px;
        left: 24px;
      }
    }
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 100;
  border: none;
  color: transparent;
  overflow: hidden;
  padding: 18px;
  font-size: 0;
  margin-top: -35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.15s ease;

  &.slick-disabled {
    opacity: 0;
    cursor: default;
  }

  &:after {
    content: "";
    position: relative;
    z-index: 10;
    width: 32px;
    height: 32px;
    display: block;
    border-radius: 32px;
    background: transparent;
    transition: background 0.25s ease;
  }

  &:hover {
    &:after {
      background: #fff !important;
    }
  }

  svg {
    position: absolute;
    z-index: 20;
    transition: margin 0.15s ease;
    path {
      stroke: #fff;
      transition: stroke 0.25s ease;
    }
  }

  &.next {
    right: -85px;
    svg {
      transform: rotate(180deg);
      margin-left: 2px;
    }
    &:active {
      svg {
        margin-left: 7px;
      }
    }
  }
  &.prev {
    left: -85px;
    svg {
      margin-right: 2px;
    }
    &:active {
      svg {
        margin-right: 7px;
      }
    }
  }
}

.costs {
  &:hover {
    .slick-arrow {
      &:after {
        background: #ececec;
      }
      svg path {
        stroke: #ee3927;
      }
    }
  }
}

.slick-dots {
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  margin-top: -24px;

  @include respond-down(M) {
    padding-bottom: 30px;
  }

  li {
    cursor: pointer;
    font-size: 0;
    display: block;
    width: 22px;
    height: 4px;
    border-radius: 8px;
    background: #d9d9d9;
    margin: 4px;
    transition:
      background 0.15s ease,
      width 0.25s ease,
      height 0.25s ease;

    @include respond-down(M) {
      width: 16px;
      height: 4px;
    }

    button {
      display: none;
    }

    &.slick-active {
      background: #ee3927;
      width: 44px;
      height: 8px;

      @include respond-down(M) {
        width: 16px;
        height: 4px;
      }

      .i-item p {
        position: relative;
        top: -30px;
      }
    }
  }
}

.about.our-costs-block {
  .costs {
    .f-items {
      @include respond-down(M) {
        align-items: normal;
      }

      .slide {
        width: auto;
        padding-left: 24px;
        margin-right: 0;
        flex: 1 1 30%;

        h3 {
          position: relative;
          z-index: 10;
        }

        p {
          position: relative;
          z-index: 10;
          font-size: 13px !important;
        }
      }
    }
  }
}

.our-costs-block {
  margin-bottom: 142px;

  @include respond-down(M) {
    margin-bottom: 30px;
    padding: 0;
  }

  .container {
    @include respond-down(M) {
      padding: 0;
    }
  }

  .prefix {
    margin-bottom: 60px;

    @include respond-down(M) {
      margin-bottom: 24px;
    }

    h2 {
      color: #fff;
    }
    .center {
      fill: #fff;
    }
  }

  &.why-block {
    .container {
      @include respond-down(M) {
        padding: 0;
      }
    }

    .costs {
      .slick-slide {
        @include respond-down(M) {
          transition: opacity 0.2s ease;
          opacity: 0;
          margin: 0;
        }
        &.slick-current {
          @include respond-down(M) {
            opacity: 1;
          }
        }
      }
      .slick-list {
        @include respond-down(M) {
          padding: 0;

          &::before,
          &::after {
            display: none;
          }
        }
      }
      .f-items {
        @include respond-down(M) {
          display: block;
        }
        .slide {
          @include respond-down(M) {
            height: 260px;
            padding: 100px 20px 20px 20px;
            text-align: center;
            display: inline-flex !important;
            align-items: center;
          }
          .roic {
            @include respond-down(M) {
              left: 50%;
              top: 20px;
              margin-left: -35px;
              background: transparent;

              svg {
                transform: scale(1.5, 1.5);
                path {
                  //stroke: rgba(144, 144, 144, 1);
                  // fill: rgba(144, 144, 144, 1);
                }
              }
            }
          }
          h3 {
            @include respond-down(M) {
              font-size: 14px;
              margin-bottom: 8px;
            }
          }
          p {
            @include respond-down(M) {
            }
          }
        }
      }
    }

    .svg-wrapper {
      svg {
        transform: scale(-1, 1);

        @include respond-down(M) {
          transform: scale(-0.5, 0.5);
          top: -150px;
          width: 430px;
          left: 24px;
        }
      }
    }
  }
}

.our-team {
  padding-bottom: 0;
  margin-bottom: 80px;

  @include respond-down(M) {
    padding-top: 24px;
    margin-bottom: 24px;
    overflow: hidden;
  }

  & > .container {
  }

  .prefix {
    @include respond-down(M) {
      padding: 0 3%;
    }
  }

  .team-slider {
    position: relative;
    padding-top: 100px;

    @include respond-down(M) {
      padding-top: 20px;
    }

    .arrows {
      z-index: 200;
      left: 50%;
      justify-content: flex-start;
      position: absolute;
      top: 100px;
      padding-left: 17px;

      @include respond-down(M) {
        padding-left: 0px;
        top: 74px;
      }
    }

    .current-slides {
      margin-bottom: 100px;
      height: 360px;
      position: relative;
      z-index: 100;

      @include respond-down(M) {
        margin-bottom: 0px;

        height: 200px;
      }

      .item {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        z-index: 100;
        transition: opacity 0.35s ease;

        @include respond-down(M) {
          height: 200px;
        }

        .photo {
          user-select: none;
          height: 100%;
          width: 460px;
          height: 360px;
          background: #ee3927;
          border-radius: 28px;
          overflow: visible;
          position: relative;

          @include respond-down(L) {
            border-radius: 18px;
          }
          @include respond-down(M) {
            height: 108px;
            width: 50%;
            background: transparent;
          }

          &::after {
            z-index: -1;
            background: #ececec;
            border-radius: 8px;
            position: absolute;
            left: 0px;
            width: 200%;
            top: 0px;
            bottom: 0;

            @include respond-down(M) {
              border-radius: 28px;
              background: #ee3927;
              content: "";
            }
          }

          .img-wrapper {
            height: 420px;
            overflow: hidden;
            overflow-y: hidden;
            position: absolute;
            bottom: 0;
            width: 80%;
            left: 10%;

            @include respond-down(M) {
              height: 210px;
              bottom: 0;
              width: 150px;
              left: 15px;
            }
          }

          img {
            display: block;
            margin: auto;

            @include respond-down(M) {
              margin-bottom: -30px;
            }
          }
        }

        h4 {
          font-weight: 700;
          font-size: 24px;
          color: #3d3d3d;
          margin-bottom: 0.8em;

          @include respond-down(M) {
            font-size: 14px;
            line-height: 1.2em;
            margin-bottom: 0.3em;
          }
        }

        .meta {
          width: 460px;
          padding-left: 20px;
          font-size: 14px;

          @include respond-down(M) {
            padding-left: 0px;
            padding-right: 20px;
            width: 50%;
            font-size: 14px;
            height: 108px;
            display: flex;
            justify-content: center;
            flex-direction: column;
          }

          h4 {
            @include respond-down(M) {
              display: none;
            }
          }

          .role {
            display: block;
            font-size: 20px;
            color: #bcbcbc;
            border-bottom: 1px solid #d6d6d6;
            line-height: 1.3em;
            padding-bottom: 15px;
            margin-bottom: 15px;

            @include respond-down(M) {
              color: #fff;
              font-size: 14px;
              line-height: 1.2em;
              padding-bottom: 8px;
              margin-bottom: 8px;
            }
          }
          br {
            @include respond-down(M) {
              display: none;
            }
          }

          .email {
            color: #3d3d3d;
            line-height: 1.5em;

            @include respond-down(M) {
              font-size: 14px;
              color: #fff;
              margin-bottom: 0;
            }
          }
        }

        .name-mobile {
          display: none;

          @include respond-down(M) {
            display: block;
            position: absolute;
            left: 50%;
            width: 50%;
            bottom: 154px;
            padding-right: 20px;
          }
        }

        &.hidden {
          opacity: 0;
          z-index: 1;
        }
      }
    }

    .all-slides {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;

      @include respond-down(M) {
        display: none;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 0 10px;
      }

      .item-photo {
        user-select: none;
        cursor: pointer;
        position: relative;
        padding-bottom: 120%;
        overflow: hidden;
        //background: rgba(255, 0, 0, 0.1);

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          height: 100px;
          border-radius: 36px;
          background: #e3e3e3;
          z-index: 1;

          @include respond-down(M) {
            border-radius: 18px;
            height: 50px;
          }
        }

        img {
          position: absolute;
          top: 0;
          width: 90%;
          left: 5%;
          z-index: 30;
        }
      }
    }

    .team200 {
      display: flex;

      @include respond-down(M) {
        align-items: flex-end;
        display: none;
      }

      .img {
        width: 214px;
        @include respond-down(S) {
          width: auto;
          height: 200px;
          max-width: initial;
        }
      }
      p {
        padding-top: 150px;
        color: #ee3927;
        font-size: 24px;
        line-height: 1.2em;

        @include respond-down(S) {
          font-size: 14px;
          padding-top: 30px;
          margin-bottom: 20px;
        }

        .big {
          display: block;
          font-weight: 700;
          font-size: 48px;
          margin-bottom: 20px;

          @include respond-down(S) {
            font-size: 52px;
          }
        }
      }
    }
  }
}

.our-result {
  padding: 140px 0;

  @include respond-down(M) {
    padding: 80px 30px;
  }

  p {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    display: flex;
    flex-direction: column;

    span:nth-child(1) {
      padding-right: 130px;
    }
    span:nth-child(2) {
      padding-left: 130px;
    }
    span:nth-child(3) {
      padding-left: 40px;
    }

    @include respond-down(M) {
      font-size: 24px;
      //text-align: left;

      span {
        padding: 0 !important;
      }
    }
  }
}
