.button {
  display: flex;
  height: 52px;
  padding: 0;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 240px;
  z-index: 1;

  @include respond-down(L) {
    width: 179px;
  }
  @include respond-down(L) {
    height: 38px;
  }

  &.block {
    width: 100%;
  }

  &.full{
    background-color: var(--color-main);

    &:after{
      background-color: var(--color-bold);
    }

    .wrapper-arrow{
      background-color: var(--color-bold);

      svg{
        .cls-1{
          fill: none;
        }
      }
    }

    .text{
      color: #ffffff;
    }
  }

  &.opacity{
    background-color: white;


    .wrapper-arrow{
      background-color: var(--color-main);
    }

    &:after{
      background-color: var(--color-main);
    }

    .text{
      color: var(--color-main);
      border: 1px solid var(--color-main);
    }

    &:hover {
      .text {
        color: #ffffff;
      }
      .wrapper-arrow{
        background-color: var(--color-bold);
      }
    }
  }

  &.white{
    background-color: transparent;


    .wrapper-arrow{
      background-color: white;

      svg{
        .cls-1{
          stroke: var(--color-main);
          fill: none;
        }
      }
    }

    &:after{
      background-color: white;
    }

    .text{
      color: white;
      border: 1px solid white;
    }

    &:hover {
      .text {
        color: var(--color-main);
      }
      .wrapper-arrow{
        background-color: $col_70;


      }
    }
  }


  &:after{
    position: absolute;
    z-index: 2;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    transition: right 0.5s cubic-bezier(0, 0.5, 0.6, 1);
  }

  &:hover{
    &:after{
      right: 0%;
    }
  }

  .text {
    transition: color 0.35s linear;
    position: relative;
    z-index: 3;
    display: flex;
    height: inherit;
    font-family: $font;
    flex: 1 1 auto;
    font-size: 18px;
    justify-content: center;
    align-items: center;

    @include respond-down(L){
      font-size: 14px;
    }
  }

  .wrapper-arrow {
    transition: background-color 0.35s linear;
    position: relative;
    z-index: 3;
    height: inherit;
    flex: 0 0 47px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-down(L){
      flex: 0 0 37px;
    }

    svg{
      height: 18px;
      width: 10.5px;

        .cls-1{
          fill: none;
        }
    }
  }


  &.mini{
    flex: 0 0 52px;


    .wrapper-arrow{
     flex:  1 1 auto;
    }

    @include respond-down(L) {
      flex: 0 0 38px;
    }

    &:after{
      display: none;
    }
  }
}