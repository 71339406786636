.footer {
  padding-bottom: 78px;
  display: flex;
  position: relative;
  justify-content: space-between;

  @include respond-down(M) {
    padding-bottom: 20px;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .wrapper-logo {
    display: flex;
    justify-content: space-between;
    flex: 0 0 30%;

    @include respond-down(M) {
      display: none;
    }

    .logo-default svg {
      width: 100px;
      margin-top: -10px;
      .theme-color {
        fill: #ee3927;
      }

      svg.RFL path {
        &.R {
          fill: #ee3927;
        }
        &.G {
          fill: #444a4b;
        }
      }
    }
  }

  .f-services {
    min-width: 160px;
    display: flex;
    flex-direction: column;

    @include respond-down(M) {
      flex: 0 0 50%;
      order: 2;
    }

    .sub-menu {
      display: none;
    }

    .menu-item {
      list-style: none !important;
      font-size: 16px;
      margin-bottom: 10px;
      display: block;

      &.disable-footer {
        display: none;
      }

      a {
        display: inline-block;
        color: #3d3d3d;
        font-weight: 500;
        padding: 5px;
        transition: color 0.15s ease;

        @include respond-down(M) {
          font-size: 14px;
          color: #ee3927;
        }

        &:hover {
          color: #bcbcbc;
        }
      }
    }
  }

  .f-directions {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    margin-right: 70px;

    @include respond-down(M) {
      flex: 0 0 50%;
      order: 1;
      margin: 0;
    }

    a {
      font-size: 16px;
      margin-bottom: 10px;
      color: #3d3d3d;
      font-weight: 700;
      padding: 5px;
      transition: color 0.15s ease;

      @include respond-down(M) {
        color: rgba(61, 61, 61, 1);
        font-size: 14px;
      }

      &:hover {
        color: #bcbcbc;
      }
    }
  }

  .phone {
    @include respond-down(M) {
      order: 3;
      border-top: 1px solid #d9d9d9;
      flex: 0 0 100%;
      padding-top: 24px;
      margin-top: 10px;
      margin-bottom: 24px;
    }

    .big-phone {
      border-bottom: 1px solid #ef3a27;
      position: relative;
      white-space: nowrap;
      color: #3d3d3d;
      font-size: 48px;
      font-weight: 700;
      transition: color 0.15s ease;

      @include respond-down(M) {
        font-size: 24px;
        color: #ee3927;
        border-bottom: none;
      }

      &:hover {
        color: #ee3927;
      }
    }
  }

  .wrapper-obercon {
    position: absolute;
    right: 10px;
    bottom: 20px;
  }

  &.footer-sub {
    padding-bottom: 80px !important;
    justify-content: flex-start;
    font-size: 14px;
    color: #bcbcbc;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .pc {
      margin-right: 20px;
    }

    @include respond-down(M) {
      padding-bottom: 24px !important;
      flex-direction: column;
      font-size: 11px;
      color: #909090;

      span {
        display: none;
      }
    }

    .wrapper-copy {
      flex: 0 0 30%;

      @include respond-down(M) {
        //margin-top: 10px;
        //order: 2;
      }
    }

    .pc {
      @include respond-down(M) {
        color: rgba(144, 144, 144, 1);
        text-decoration: underline;
      }
    }

    a {
      color: #d9d9d9;
      transition: color 0.15s ease;
      &:hover {
        color: #ee3927;
      }
    }
  }
}
