.part--contacts {
  @include respond-property(margin-bottom, 90px, 80px, 74px, 36px, 36px);
  &.map-box {
    z-index: 30;
    position: relative;
  }

  .heading {
    @include respond-property(margin-bottom, 18px, 11px, 10px, 13px, 13px);
  }

  .header {
    @include respond-property(margin-bottom, 43px, 30px, 28px, 34px, 29px);
    @include respond-property(font-size, 25px, 18px, 18px, 18px, 14px);
    color: $col_10;
  }

  .feedback-send-methods {
    display: flex;
    justify-content: space-between;

    @include respond-down(M) {
      flex-wrap: wrap;
    }

    .select-method {
      flex: 0 0 35.8%;

      @include respond-down(M) {
        flex: 1 1 100%;
        max-width: 475px;
        margin-bottom: 65px;
      }

      @include respond-down(S) {
        max-width: unset;
        margin-bottom: 58px;
      }

      @include respond-down(XS) {
        margin-bottom: 52px;
      }

      .select-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        .item {
          @include respond-property(width, 97px, 69px, 77px, 77px, 52px);
          @include respond-property(height, 97px, 69px, 77px, 77px, 52px);
          border-width: 2px;
          border-color: rgba(67, 73, 74, 0.1);
          background: transparent;
          position: relative;
          transition:
            border 0.15s linear,
            background 0.15s linear;

          @include respond(XS) {
            border-color: var(--color-main);
          }

          .name {
            @include respond-property(font-size, 18px, 13px, 14px, 14px, 11px);
            letter-spacing: 0.1em;
            position: absolute;
            width: 100%;
            bottom: -40px;
            left: 0;
            text-align: center;
            transition: color 0.15s linear;

            @include respond-down(S) {
              bottom: -32px;
              width: 150%;
              left: -25%;
            }

            @include respond(XS) {
              bottom: -25px;
              color: var(--color-main);
            }
          }

          &:hover {
            border-color: rgba(67, 73, 74, 0.4);

            svg {
              .rectangle {
                fill: #fff;
              }

              .icon {
                fill: var(--color-main);
              }
            }

            .name {
              color: $col_40;
            }
          }

          &.current {
            cursor: default;
            border-color: var(--color-main);
            background: var(--color-main);

            svg {
              .rectangle {
                fill: #fff;

                @include respond(XS) {
                  fill: var(--color-main);
                }
              }

              .icon {
                fill: var(--color-main);

                @include respond(XS) {
                  fill: #fff;
                }
              }
            }

            .name {
              color: var(--color-main);
            }
          }
        }
      }
    }

    .form-type {
      flex: 0 0 57.4%;
      @include respond-property(margin-bottom, 83px, 58px, 52px, 29px, 49px);

      @include respond-down(M) {
        flex: 0 0 100%;
      }

      .type {
        @include respond-property(margin-bottom, 37px, 26px, 30px, 21px, 0px);
        display: flex;
        justify-content: space-between;

        @include respond(XS) {
          flex-wrap: wrap;
        }

        select {
          display: none;
        }

        .form-control,
        .button {
          flex: 0 0 31.3%;
        }

        .form-control {
          &[data-show="email"] {
            display: none;
          }

          @include respond-down(M) {
            flex: 0 0 44.5%;
          }

          @include respond-down(S) {
            flex: 0 0 39.5%;
          }

          @include respond(XS) {
            flex: 0 0 100%;
            margin-bottom: 19px;

            &.second {
              flex: 1 1 50%;
              margin-right: 14px;
            }
          }
        }

        .button {
          height: 54px;

          @include respond-down(L) {
            height: 38px;
          }

          @include respond-down(M) {
            height: 42px;
            flex: 0 0 42px;

            .text {
              display: none;
            }
          }

          @include respond(XS) {
            height: 38px;
            flex: 0 0 38px;
          }

          .wrapper-arrow {
            flex: 0 0 54px;

            @include respond-down(L) {
              flex: 0 0 38px;
            }

            @include respond-down(M) {
              flex: 0 0 42px;
            }

            @include respond(XS) {
              flex: 0 0 38px;
            }
          }
        }

        .wrapper-success-block {
          .success-block {
            flex-direction: row;
            align-items: center;

            .message {
              font-size: 14px;
              width: auto;
              margin-left: 10px;
            }

            svg {
              width: 25px;
              height: 25px;
              margin-bottom: 0;
            }
          }
        }
      }

      .confirmation {
        @include respond-property(font-size, 15px, 13px, 12px, 12px, 10px);
        line-height: 1.3;
        letter-spacing: 0.05em;
        color: $col_40;

        a {
          color: var(--color-main);
          transition: color 0.15s linear;

          &:hover {
            color: var(--color-bold);
          }
        }
      }
    }
  }
}

.contacts-maps {
  position: relative;
  z-index: 20;
  overflow: hidden;

  @include respond-down(M) {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }

  @include respond(XS) {
    .separated {
      flex-wrap: wrap;
    }
  }

  .content.map-block {
    flex: 0 0 50%;

    @include respond(S) {
      margin-bottom: 0;
    }

    @include respond(XS) {
      flex: 0 0 100%;
      margin-bottom: 0;
    }

    .map-container {
      @include respond-property(height, 620px, 480px, 480px, 420px, 420px);
      background: #eee;
      position: relative;
      overflow: hidden;

      #map-0 {
        position: absolute;
        top: 0;
        bottom: -30px;
        left: 0;
        right: 0;
      }

      .pin-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        z-index: 20;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        transition: opacity 0.35s ease;
        opacity: 0;

        &.visible {
          display: flex;
        }

        &.fade {
          opacity: 1;
        }
      }

      .address-item {
        display: none;
        width: 356px;
        background: #fff;
        position: relative;
        transition:
          opacity 0.25s ease,
          transform 0.35s ease;
        opacity: 0;
        transform: scale(0.8);

        @include respond-down(XS) {
          width: 90%;
        }

        &.visible {
          display: block;
        }

        &.fade {
          opacity: 1;
          transform: scale(1);
        }

        .title {
          height: 48px;
          font-size: 20px;
          font-family: $font;
          font-weight: bold;
          position: relative;
          align-items: center;
          display: flex;
          padding-left: 41px;

          svg {
            position: absolute;
            left: 0;
            top: -4px;
            width: 37px;
          }
        }

        .rectangle {
          fill: rgba(0, 0, 0, 0.1) !important;
        }

        .close {
          position: absolute;
          width: 30px;
          height: 30px;
          border: 1px solid #ed3927;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          right: -15px;
          top: -15px;
          background: #fff;
          z-index: 40;
          transition: background 0.25s ease;

          &:hover {
            background: #eee;
          }

          svg {
            width: 14px;

            path {
              stroke: #000;
            }
          }
        }

        .body {
          background: #ed3927;
          padding: 20px 40px;
          color: #fff;
          font-size: 14px;

          .address {
            margin-bottom: 30px;
            line-height: 1.35em;
          }

          .button {
            width: 242px;
            height: 38px;
            border: 1px solid #fff;
            transition: background 0.25s ease;

            &:hover {
              background: rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
  }
}
