html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  -webkit-text-size-adjust: none;
  color: #3d3d3d;
  font-size: 14px;
}

.page-content {
  overflow: hidden;
  position: relative;
}

.container {
  padding: 0 8%;
  width: 100%;

  @include respond(L) {
    padding: 0 5%;
  }
  @include respond-down(S) {
    padding: 0 16px;
  }

  .pl {
    padding-left: 10%;
  }
}

.document-text {
  line-height: 1.4em;

  .accordion {
    margin-bottom: 30px;
  }

  .header {
    font-weight: 400;
    font-size: 110%;
    margin-bottom: 15px;
  }

  .body {
    ul {
      box-sizing: border-box;
    }

    ul.text > li {
      position: relative;
      margin-bottom: 15px;
      padding-left: 30px;
    }

    ul.text > li:before {
      content: " ";
      position: absolute;
      top: 9px;
      left: 0px;
      width: 20px;
      height: 1px;
      background-color: #00142c;
    }

    ul.text > li,
    ol.text > li {
      color: #00142c;
    }

    ul.text > li:before {
      content: " ";
      position: absolute;
      top: 15px;
      left: 0px;
      width: 15px;
      height: 1px;
      background-color: #00142c;
    }
  }
}

.indent {
  clear: both;
  display: block;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background: transparent;
  border: none;
}

[data-debug="debug"] #DEBUG {
  position: fixed;
  padding: 5px;
  opacity: 0.5;
  background: #f00;
  color: #fff;
  border-radius: 5px;
  left: 10px;
  bottom: 10px;
  z-index: 99999;
  &:after {
    content: "XL";
    @include respond-down(L) {
      content: "L";
    }
    @include respond-down(M) {
      content: "M";
    }
    @include respond-down(S) {
      content: "S";
    }
    @include respond-down(XS) {
      content: "XS";
    }
  }
}
