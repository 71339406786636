.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
  gap: 6px;

  .tag {
    font-size: 14px;
    padding: 0 16px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    transition:
      background 0.15s ease,
      color 0.15s ease;
    border: 1px dashed #9b9b9b;
    color: #909090;

    @include respond-down(M) {
      border: 1px dashed #ececec;
      color: #ececec;
      font-size: 13px;
      height: 26px;
    }

    &:hover {
      color: #ee3927 !important;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.news-list {
  margin-bottom: 130px;

  @include respond-down(M) {
    margin-bottom: 30px;
  }

  .first-line,
  .next-lines {
    display: grid;
    grid-template-columns: 2fr 1fr;
    row-gap: 20px;
    margin-bottom: 20px;

    @include respond-down(S) {
      display: flex;
      flex-direction: column;
    }
  }

  .first-line {
    .big-post {
      cursor: pointer;
      margin-right: 10px;
      @include respond-down(M) {
        margin-right: 0;
      }
    }
    .small-post {
      cursor: pointer;
      margin-left: 16px;
      @include respond-down(M) {
        margin-left: 0;
      }
    }
  }

  .next-lines {
    column-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .date {
    display: inline-flex;
    align-items: center;
    padding: 0 16px;
    height: 30px;
    background: #ee3927;
    border: 1px solid #ee3927;
    color: #fff;
    position: absolute;
    z-index: 5;
    right: 36px;
    bottom: 24px;
    border-radius: 8px;

    @include respond-down(M) {
      font-size: 14px;
      top: auto;
      right: 36px;
      bottom: 24px;
    }
  }

  .meta {
    padding: 36px;
    margin-bottom: 30px;

    @include respond-down(M) {
      padding: 24px 36px;
      margin-bottom: 0;
    }
  }

  .read-time {
    font-size: 14px;
    color: #d6d6d6;
  }

  .big-post {
    position: relative;
    padding-bottom: 61%;
    background: no-repeat center center;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid #ececec;

    @include respond-down(M) {
      padding-bottom: 84%;
    }

    .tags .tag {
      color: #ececec;
      border-color: #ececec;
    }

    &::after {
      content: "";
      background: rgba(19, 19, 19, 0.5);
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    .read-time {
      color: #ececec;
      padding-top: 24px;
    }

    .meta {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;

      h2 {
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        line-height: 1.3em;
        margin-bottom: 16px;
        max-width: 70%;

        @include respond-down(M) {
          font-size: 18px;
          max-width: 100%;
          max-height: 47px;
          overflow: hidden;
        }
      }

      .url {
        max-width: 60%;
        display: inline-block;
        @include respond-down(M) {
          max-width: 100%;
        }
      }

      .desc {
        max-width: 60%;
        font-size: 14px;
        color: #fff;
        line-height: 1.4em;
        @include respond-down(M) {
          font-size: 13px;
          max-width: 100%;
          max-height: 35px;
          overflow: hidden;
        }
      }
    }
  }

  .small-post {
    cursor: pointer;
    box-sizing: border-box;
    background: #ececec;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    min-height: 460px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ececec;

    @include respond-down(M) {
      min-height: 0;
      padding-bottom: 40px;
    }

    .date {
      @include respond-down(M) {
        right: 36px;
        bottom: 24px;
      }
    }

    .meta {
      @include respond-down(M) {
        padding: 36px;
      }
    }

    &.img-top {
      @include respond-down(M) {
        padding-bottom: 0;
      }
      & > .date {
        @include respond-down(M) {
          display: none;
        }
      }
    }

    &.img-bottom {
      @include respond-down(M) {
        padding-bottom: 0;
      }

      & > .date {
        @include respond-up(M) {
          display: none;
        }
      }
      .media {
        order: 2;

        @include respond-down(M) {
          order: 10;
        }
      }

      .meta {
        order: 1;
        flex: 1 1 100%;

        @include respond-down(M) {
          order: 1;
        }
      }
    }

    &.media-no {
      background: #fff;

      @include respond-down(M) {
        padding-bottom: 20px;
      }

      .date {
        display: flex;
        position: absolute;
        bottom: 32px;
        right: 40px;
        top: auto;

        @include respond-down(M) {
          right: 36px;
          bottom: 24px;
        }
      }

      .tag {
        position: initial;
        display: inline-flex;
      }

      .read-time {
        display: block;
        margin-bottom: 16px;
        //position: absolute;
        //bottom: 32px;
        //left: 40px;
      }
    }

    .media {
      flex: 0 0 248px;
      height: 248px;
      //padding-bottom: 61%;
      background: no-repeat center center;
      background-size: cover;
      border-radius: 16px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      .date {
        @include respond-down(M) {
          //display: none;
        }
      }

      &::after {
        content: "";
        background: rgba(19, 19, 19, 0.5);
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
      }
    }

    .tag {
      color: #9b9b9b;
      border-color: #9b9b9b;
    }

    .read-time {
      color: #9b9b9b;

      @include respond-down(M) {
        font-size: 14px;
      }
    }

    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.3em;
      margin-bottom: 8px;
      color: #3d3d3d;

      @include respond-down(M) {
        font-size: 18px;
      }
    }

    .desc {
      font-size: 14px;
      line-height: 1.3em;
      margin-bottom: 24px;

      @include respond-down(L) {
        font-size: 13px;
      }
    }
  }

  .load-more-wrapper {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-down(L) {
      padding-top: 20px;
    }
  }
}
