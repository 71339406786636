.direction-intro {
  padding-top: 140px;
  padding-bottom: 140px;

  @include respond-down(M) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.d-transport {
    padding-bottom: 200px;
    @include respond-down(M) {
      padding-bottom: 80px;
    }
  }

  &.mini {
    padding-left: 10%;
    padding-right: 10%;

    @include respond-down(M) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .prefix {
    margin-bottom: 30px;

    @include respond-down(M) {
      margin-bottom: 20px;
    }

    svg {
      @include respond-down(M) {
        //margin-right: 0;
      }
    }
  }

  .text-alerts {
    padding-right: 18%;

    @include respond-down(M) {
      padding-right: 0;
      margin-bottom: 6px;
    }
  }
  .flex {
    .text-alerts {
      padding-right: 40px;
      margin-bottom: 10px;
    }
    .sub-alerts {
      font-size: 24px;
      line-height: 1.3em;

      @include respond-down(M) {
        font-size: 14px;
        margin-bottom: 16px;
      }
    }
    .desc {
      flex: 0 0 25%;
      line-height: 1.3em;

      @include respond-down(M) {
        font-size: 14px;
      }

      strong {
        display: block;
        margin-bottom: 0.5em;

        @include respond-down(M) {
          margin-bottom: 0em;
        }
      }
    }
  }
}

.we-have {
  padding-bottom: 124px;

  @include respond-down(M) {
    padding-bottom: 40px;
  }

  .round-items .item p {
    font-size: 14px;
  }

  .prefix {
    padding-top: 24px;
  }
}

.round-items {
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: flex-start;

  @include respond-down(M) {
    column-gap: 8px;
    row-gap: 8px;
  }

  .item {
    min-height: 150px;
    padding-left: 110px;
    padding-top: 24px;
    padding-right: 24px;

    @include respond-down(M) {
      min-height: 118px;
      width: 100%;
    }

    p {
      font-size: 14px;
      position: relative;
      z-index: 20;
      font-weight: 500;
      line-height: 1.3em;

      @include respond-down(M) {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
    .bg {
      left: auto;
      right: 0px;
      bottom: -10px;
    }
    .roic {
      left: 24px;
      top: 24px;
      bottom: auto;
      right: auto;

      @include respond-down(M) {
        width: 70px;
        height: 70px;
        border-radius: 28px;
      }
    }
    .label {
      font-size: 18px;
      font-weight: 700;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 0;
      padding-bottom: 0.4em;
      margin-bottom: 16px;
      position: relative;
      z-index: 10;

      @include respond-down(M) {
        font-size: 14px;
        margin-bottom: 6px;
      }
    }
  }
}

.pro-text {
  position: relative;
  height: 476px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 130px;

  @include respond-down(M) {
    height: 360px;
    margin-bottom: 0px;
  }

  svg {
    position: absolute;
    width: 1366px;
    margin: auto;
    z-index: 1;

    @include respond-down(M) {
      width: 600px;
    }
  }

  .text-alerts {
    @include respond-down(M) {
      text-align: center;
    }
    span {
      display: block;
      &:nth-child(1) {
        margin-left: -20%;
        @include respond-down(M) {
          margin-left: 0;
        }
      }
      &:nth-child(2) {
        margin-left: 40%;
        @include respond-down(M) {
          margin-left: 0;
        }
      }
    }
  }

  &.pro-just {
    white-space: nowrap;
    @include respond-down(M) {
      height: 410px;
    }

    & > svg {
      @include respond-down(M) {
        width: 830px;
        margin-left: -300px;
      }
    }

    .text-alerts {
      width: 900px;

      @include respond-down(M) {
        width: 360px;
        white-space: wrap;
      }

      span {
        &:nth-child(1) {
          margin-left: 0;
        }
        &:nth-child(2) {
          margin-left: 20%;

          @include respond-down(M) {
            margin-left: 2%;
          }
        }
        &:nth-child(3) {
          margin-left: 30%;
          @include respond-down(M) {
            margin-left: 10%;
            color: #ee3927;
          }
        }
      }
    }
  }
}

.container.dir-costs-wrapper {
  @include respond-down(M) {
    padding: 0;
  }
}

.costs.dir-costs {
  border-radius: 28px;
  background: #ececec;
  margin-bottom: 130px;

  @include respond-down(M) {
    border-radius: 0;
    padding-bottom: 16px;
    margin-bottom: 24px;
  }

  .prefix {
    margin-bottom: 65px;

    @include respond-down(M) {
      margin-bottom: 24px;
    }
  }

  .steps-items {
    width: 100%;
    margin-bottom: 60px;

    @include respond-down(M) {
      margin-bottom: 0px;
    }

    .slide {
      width: 360px;
      height: 180px;
      min-height: auto;
      background: #fff;
      display: flex;
      flex-direction: column;
      position: relative;
      padding-left: 110px;

      @include respond-down(M) {
        width: 100%;
        height: auto;
        padding-left: 90px;
        margin-bottom: 8px;

        &:nth-child(2n) {
          background: #d9d9d9;
        }
      }

      .roic {
        left: 24px;
        top: 24px;
        bottom: auto;
        right: auto;

        color: #fff;
        text-shadow: #000 0 0 2px;
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;

        @include respond-down(M) {
          width: 50px;
          height: 50px;
          border-radius: 16px;
          font-size: 28px;
        }
      }
    }

    .slick-slide {
      &:nth-child(2n) {
        .slide {
          background: #d9d9d9;

          .label {
            border-color: #ececec;
          }

          .roic {
            background: #fff;
          }
        }
      }
    }

    @include respond-down(M) {
      .slide:nth-child(2n) {
        .label {
          border-color: #ececec;
        }
      }
    }
  }

  .slick-dots {
    position: relative;
    bottom: -55px;
  }

  .slick-list:before {
    background: linear-gradient(90deg, #ececec 0%, rgba(0, 0, 0, 0) 100%);
  }
  .slick-list:after {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #ececec 100%);
  }
}

.dir-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
  line-height: 1.3em;

  @include respond-down(M) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.items-for {
  display: flex;
  column-gap: 20px;
  padding-bottom: 130px;

  @include respond-down(M) {
    padding-bottom: 20px;
  }

  .slick-dots {
    @include respond-down(M) {
      margin-top: 12px;
    }
  }

  .slick-list {
    @include respond-down(M) {
    }
  }

  .item-for {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    overflow: hidden;
    background: #ececec;
    border: 1px solid #ececec;
    flex: 1 1 33.333%;
    position: relative;

    @include respond-down(M) {
      flex: 0 0 100%;
    }

    &:nth-child(2) {
      background: #f4f4f4;
    }
    &:nth-child(3) {
      background: #fff;
    }

    .n {
      display: none;
      font-size: 14px;
      font-weight: 700;
      position: absolute;
      right: 24px;
      top: 30px;
      color: #ee3927;

      @include respond-down(M) {
        display: block;
      }
    }

    .txt {
      align-items: flex-start;
      padding: 36px;
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;

      @include respond-down(M) {
        padding: 24px;
      }

      .label {
        display: inline-flex;
        align-items: center;
        padding: 0 16px;
        height: 30px;
        background: #ee3927;
        border: 1px solid #ee3927;
        color: #fff;
        border-radius: 8px;

        margin-bottom: 24px;
        font-size: 14px;
        position: relative;
        z-index: 10;

        @include respond-down(M) {
          margin-bottom: 18px;
        }
      }
      .for-text {
        line-height: 1.3em;
        position: relative;
        z-index: 10;
        font-weight: 500;
      }
    }

    .img {
      padding-bottom: 64%;
      background: no-repeat center center;
      background-size: cover;
      border-radius: 16px;
    }
  }
}

.steps-to {
  position: relative;
  z-index: 20;
  padding: 60px 0;
  background: #fff;
  margin-bottom: 140px;

  @include respond-down(M) {
    padding: 36px 0;
    margin-bottom: 40px;
  }

  .slick-dots {
    margin-top: 0;
    padding-top: 40px;
    margin-bottom: 0;

    @include respond-down(M) {
      display: none;
    }

    li {
      background: #fb6556;
      &.slick-active {
      }
    }
  }

  .slick-arrow {
    margin-top: -55px;
    svg {
      path {
        stroke: #ee3927;
      }
    }
  }

  &:hover {
    .slick-arrow {
      &:after {
        background: #ececec;
      }
    }
  }

  .slick-list {
    overflow: visible;
  }

  .transfer-slider {
    margin-bottom: 10px;

    @include respond-down(M) {
      margin-bottom: 0;
    }

    .slick-slide {
      margin-right: 24px;
      width: 360px;

      .slide {
        height: 190px;
        display: inline-flex !important;
        flex-direction: column;
        align-items: flex-start;

        .label {
          font-weight: 700;
        }
      }

      &:nth-child(3n-2) {
        .item {
          background: #ee3927;
          color: #fff;
        }
      }
      &:nth-child(3n-1) {
        .item {
          background: #ececec;
          .roic {
            background: #ee3927;
            svg path {
              fill: #fff;
            }
          }
        }
      }
      &:nth-child(3n-3) {
        .item {
          background: #f4f4f4;
          .roic {
            background: #ececec;
          }
        }
      }
    }
  }

  .con-pad {
    @include respond-down(M) {
      margin: 0;
    }
  }

  .wrapper-red-items {
    .item {
      @include respond-down(M) {
        min-height: 100px;
        padding-left: 90px;
        flex-direction: column;
        display: flex;
        align-items: flex-start;

        .roic {
          height: 50px;
          width: 50px;
          border-radius: 16px;

          svg {
            width: 28px;
          }
        }

        &:nth-child(3n + 1) {
          background: #ee3927;
          .label {
            color: #fff;
            border-color: #fff;
          }
          .s-text {
            color: #fff;
          }
        }

        &:nth-child(3n + 2) {
          background: #ececec;
          .label {
            color: #ee3927;
            border-color: #ee3927;
          }

          .roic {
            background-color: #ee3927;

            svg path {
              fill: #fff !important;
            }
          }
        }

        &:nth-child(3n + 3) {
          background: #ececec;
          .label {
            color: #ee3927;
            border-color: #ee3927;
          }
        }
      }
    }
  }

  //.wra
  //
}

.while-to {
  margin-bottom: 140px;

  @include respond-down(M) {
    margin-bottom: 40px;
  }
  .prefix {
    margin-bottom: 30px;
  }
}

.while-items {
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;

  @include respond-down(M) {
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    row-gap: 8px;
    position: relative;
  }

  .one {
    height: 213px;
    padding: 24px;
    background: #fff;
    font-size: 24px;
    font-weight: 800;
    border-radius: 28px;
    position: relative;
    overflow: hidden;
    line-height: 1.3em;
    z-index: 2;

    @include respond-down(M) {
      height: 154px;
      font-size: 14px;
      border-radius: 16px;
      padding: 16px;
    }

    &.mrx {
      padding-right: 150px;
      margin-right: -150px;
      z-index: 1;
    }

    p {
      position: relative;
      z-index: 20;
      padding-right: 20%;

      @include respond-down(M) {
        padding-right: 0%;
      }
    }

    .bg {
      position: absolute;
      left: 0px;
      bottom: -10px;
      z-index: 1;
    }

    &.image {
      background: no-repeat center center;
      background-size: cover;
    }

    svg.wh,
    svg.while-pm {
      position: absolute;
      right: 0;
      bottom: 0;

      @include respond-up(L) {
        display: none;
      }
    }

    @include respond-down(M) {
      &.pm1 {
        order: 20;
        padding-right: 0;
        margin-right: 0;
        padding-left: 150px;
        margin-left: -150px;
        z-index: 1;
        background: #ee3927;
        color: #fff;
      }
      &.pm2 {
        order: 19;
      }
    }

    &.gr {
      background: #ececec;

      .roic {
        background: #fff;
      }

      @include respond-down(M) {
        background: #fff;
      }
    }

    &.red {
      background: #ee3927;
      color: #fff;
    }

    .roic {
      @include respond-down(M) {
        display: none;
      }
    }
  }
}

.for-to {
  margin-bottom: 140px;

  @include respond-down(M) {
    margin-bottom: 40px;
  }

  .prefix {
    margin-bottom: 30px;
  }

  @include respond-down(M) {
    background: #ee3927;
    padding-top: 24px;

    .prefix {
      margin-bottom: 22px;
      h2 {
        color: #fff;
      }
      .center {
        fill: #fff;
      }
    }
  }

  .costs {
    @include respond-down(M) {
      padding: 0 0 24px 0;
    }
  }

  .for-items {
    @include respond-down(M) {
      flex-direction: column;
      background: #fff;
      border-radius: 28px;
      padding: 14px 0;

      .item {
        padding: 16px 20px;
        background: transparent;

        &::after {
          display: none;
        }
      }
    }
  }

  .slick-dots {
    margin-top: 0;
    padding-top: 40px;

    @include respond-down(M) {
      display: none;
    }

    li {
      background: #fb6556;
      &.slick-active {
        background: #fff;
      }
    }
  }
}

.for-items {
  display: flex;
  column-gap: 20px;

  .item {
    height: 240px;
    background: #fff;
    border-radius: 28px;
    padding: 36px;
    line-height: 1.3em;
    transition:
      flex 0.2s ease,
      background 0.2s ease;
    flex: 1 1 30%;
    overflow: hidden;
    background: #d9d9d9;
    position: relative;
    cursor: default;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: auto;
      right: 0px;
      bottom: 0;
      width: 250px;
      background: linear-gradient(90deg, rgba(217, 217, 217, 0) 0%, #d9d9d9 100%);
      transition: opacity 0.2s ease;
      z-index: 12;
    }

    .inter {
      width: 320px;
      position: relative;
      z-index: 10;
      @include respond-down(M) {
        width: 100%;
      }
    }

    &.hover {
      flex: 0 0 392px;
      background: #fff;

      &::after {
        opacity: 0;
      }
    }

    .label {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 0.6em;
    }
  }
}

.delivery-part {
  margin-bottom: 140px;
  @include respond-down(M) {
    margin-bottom: 40px;
  }

  .dir-title {
    width: 30%;
    @include respond-down(M) {
      width: 100%;
    }
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 24px;
    row-gap: 38px;

    @include respond-down(M) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      gap: 8px;
    }

    .one {
      padding: 36px;

      @include respond-down(M) {
        padding: 24px;
        height: auto;
      }

      p {
        line-height: 1.15em;
        font-size: 24px;
        margin-bottom: 0.5em;
        padding-right: 0;

        @include respond-down(M) {
          font-size: 14px;
        }
      }
      p.sub {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3em;

        @include respond-down(M) {
          font-size: 14px;
        }
      }
    }
  }

  .div3 {
    .one {
      @include respond-down(M) {
        height: 154px;
      }
    }
    @include respond-down(M) {
      order: -1;
    }
  }

  .div4 {
    grid-column: span 2 / span 2;

    @include respond-down(M) {
      grid-column: initial;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50%;
      z-index: 10;

      .one {
        height: 154px;
        width: 100%;
      }
    }
  }

  .div5 {
    grid-column-start: 3;
    overflow: hidden;

    svg {
      display: none;
    }

    @include respond-down(M) {
      border-radius: 16px;
      grid-column-start: auto;
      position: relative;

      svg {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
      }

      .one {
        height: 154px;
        padding-right: 54%;
      }
    }
  }
}

.costs.tra-costs {
  .prefix {
    margin-bottom: 30px;

    @include respond-down(M) {
      margin-bottom: 24px;
    }
  }

  .for-items {
    @include respond-down(M) {
      flex-direction: column;
      gap: 8px;
    }

    .item {
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      padding-right: 40px;
      height: 162px;

      &::after {
        display: none;
      }

      .label {
        padding-bottom: 4px;
        font-weight: 700;
        color: #ee3927;
        border-bottom: 1px solid #ee3927;

        @include respond-down(M) {
          font-size: 14px;
          padding-bottom: 0px;
          margin-bottom: 0.3em;
          font-weight: 800;
        }
      }

      .for-text {
        position: relative;
        z-index: 10;
        @include respond-down(M) {
          font-size: 14px;
        }
      }
    }
  }
}
