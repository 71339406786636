.warehouse-page {
  padding-top: 130px;
  margin-bottom: 0;

  @include respond-down(M) {
    padding-top: 40px;
  }

  .pre-con-wrapper {
    @include respond-down(M) {
      margin: 0;
    }
    h2 {
      font-size: 48px;
      font-weight: 900;
      color: #3d3d3d;
      font-style: normal;
      font-weight: 700;
      line-height: 115%; /* 55.2px */
      margin-bottom: 60px;

      b {
        color: #ee3927;
      }

      @include respond-down(M) {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
      }
    }

    .pre-con-items {
      padding-left: 10%;
      padding-right: 10%;
      margin-bottom: 140px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 70px;
      row-gap: 20px;

      @include respond-down(M) {
        margin-bottom: 40px;
        grid-template-columns: 1fr 1fr;
        padding-left: 0;
        padding-right: 0;

        column-gap: 16px;
        row-gap: 16px;
      }

      .pre-con {
        @include respond-down(M) {
          border-bottom: 1px dashed #bcbcbc;
        }

        .title {
          color: #3d3d3d;
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 1.3em;
          text-decoration-line: underline;
          transition: color 0.15s ease;

          @include respond-down(M) {
            font-size: 18px;
          }

          &:hover {
            color: #ee3927;
          }
        }

        .subtitle {
          color: #909090;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.3em;
          padding-top: 6px;

          @include respond-down(M) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .full-contact-items {
    @include respond-down(M) {
      margin: 0 -16px;
    }

    .full-contact {
      margin-bottom: 68px;
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 68px;

      @include respond-down(M) {
        padding-bottom: 0px;
        border-bottom: 1px solid transparent;
        background: #fff;
        margin-bottom: 40px;
        padding-top: 24px;
      }

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      .cols-x {
        display: flex;
        margin-bottom: 40px;

        @include respond-down(M) {
          flex-direction: column;
          padding-bottom: 40px;
          margin-bottom: 0;
        }

        .sname {
          flex: 0 0 39%;
          padding-right: 30px;

          @include respond-down(M) {
            flex: 1 1 auto;
            padding-right: 0;
            position: relative;
            margin: 0 16px;
            display: flex;
            align-items: center;
            margin-bottom: 16px;
          }

          h3 {
            color: #3d3d3d;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.2em;
            margin-bottom: 30px;

            @include respond-down(M) {
              font-size: 24px;
              flex: 0 0 47%;
              top: 8px;
              margin-right: 16px;
              margin-bottom: 0;
              line-height: 1.14em;
            }
          }

          .space {
            font-size: 48px;
            color: #ee3927;
            margin-bottom: 10px;
            font-weight: 700;
            line-height: 1.3em;

            @include respond-down(M) {
              margin-bottom: 0;
              font-size: 18px;
            }
          }

          .space-desc {
            font-size: 14px;
            color: #ee3927;
            @include respond-down(M) {
              font-size: 14px;
            }
          }
        }

        .types {
          padding-top: 10px;
          line-height: 1.4em;
          font-size: 14px;
          flex: 0 0 20%;

          @include respond-down(M) {
            flex: 1 1 100%;
            padding: 0 16px;
            margin-bottom: 16px;
          }
          p {
            margin-bottom: 0.4em;
          }

          h4 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 10px;

            @include respond-down(M) {
              font-size: 14px;
              margin-bottom: 0px;
            }
          } // #konsultacija
        }

        .options {
          @include respond-down(M) {
            flex: 1 1 100%;
            padding: 0 16px;
          }
          p {
            margin-bottom: 0.4em;
            position: relative;
            padding-left: 14px;
            font-size: 14px;
            line-height: 1.4em;

            &::before {
              content: "• ";
              position: absolute;
              left: 3px;
            }
          }
        }
      }

      .media {
        display: flex;
        column-gap: 20px;
        row-gap: 20px;

        @include respond-down(M) {
          display: none;
          column-gap: 16px;
          row-gap: 16px;
          margin-bottom: 16px;
        }

        .img {
          width: 100%;
          height: 304px;
          border-radius: 8px;
          overflow: hidden;
          background: no-repeat center center;
          background-size: cover;

          @include respond-down(M) {
            height: 160px;
            border-radius: 0;
          }
        }
        &.c-2 {
          .img:nth-child(1) {
            flex: 0 0 57%;
            @include respond-down(M) {
              flex: 1 1 50%;
            }
          }
          .img:nth-child(2) {
            flex: 0 0 43%;
            @include respond-down(M) {
              flex: 1 1 50%;
            }
          }
        }
      }

      .mobile-media {
        display: none;

        @include respond-down(M) {
          display: flex;
        }
      }
    }
  }
}

.white-only {
  background: #fff;
  margin-bottom: 220px;

  @include respond-down(M) {
    margin-bottom: 40px;
    padding-bottom: 40px;
    background: linear-gradient(87deg, #fff 6.43%, #e0e0e0 87.89%);
    //background: transparent;
  }

  .svg-wrapper {
    display: none;
  }
}

.contacts-page-form {
  .svg-wrapper {
    display: none;
  }
}

.contacts-page {
  padding-bottom: 0;
  margin-bottom: 80px;

  @include respond-down(M) {
    margin-bottom: 40px;
  }

  .white-page {
    padding-top: 210px;
    padding-left: 10%;
    padding-right: 10%;

    @include respond-down(M) {
      padding-top: 70px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .map-box {
    margin-bottom: 0;
  }

  &.gray-segment.bubble:after {
    top: auto;
    bottom: 300px;
  }

  h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 36px;
  }

  .hr-red {
    height: 1px;
    background: #ee3927;
    border: none;
    margin-bottom: 36px;
  }

  .c-item {
    a {
      transition: color 0.15s ease;
      &:hover {
        color: #ee3927;
      }
    }
  }

  .big-contacts {
    display: flex;
    flex-wrap: wrap;
  }

  .small-contacts {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;

    @include respond-down(M) {
      margin-bottom: 20px;
    }
  }

  .c-item {
    margin-bottom: 36px;
    width: 33%;
    flex: 0 0 33.33%;
    padding-right: 30px;
    font-size: 13px;

    @include respond-down(M) {
      width: 100%;
      flex: 0 0 100%;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 8px;
      line-height: 1.2em;

      @include respond-down(L) {
        font-size: 22px;
      }
      @include respond-down(M) {
        font-size: 18px;
      }
    }

    .desc {
      font-size: 13px;
      color: #909090;
      line-height: 1.3em;
      font-weight: 500;
      margin-bottom: 6px;

      @include respond-down(L) {
        font-size: 19px;
      }
      @include respond-down(M) {
        font-size: 16px;
      }
    }

    .onmap {
      font-size: 13px;
      font-weight: 700;

      display: inline-flex;
      align-items: center;
      padding-top: 8px;

      svg {
        top: 0;
        position: relative;
        transition: top 0.15s ease;
        margin-right: 4px;
      }

      &:hover {
        svg {
          top: -4px;
        }
      }
    }

    .email {
      color: #3d3d3d;
      margin-bottom: 6px;
      font-weight: 700;
      text-decoration: underline;
    }
    .phone {
      margin-bottom: 6px;
      font-size: 13px;
      color: #3d3d3d;
    }
  }
}

.contacts-page-form {
  margin-bottom: 120px;

  @include respond-down(M) {
    margin-bottom: 80px;
  }
}
