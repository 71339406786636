.checkbox-wrapper {
  padding-top: 24px;
}

.post {
}

.container-mini {
  max-width: 800px;
  margin: auto;

  .header-info {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    @include respond-down(M) {
      font-size: 14px;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 20px;
    }

    .date {
      color: #ee3927;
      margin-right: 24px;
      white-space: nowrap;

      @include respond-down(M) {
        margin: 0 0 10px 0;
        display: block;
      }
    }

    .read-time {
      white-space: nowrap;
      color: #909090;
      flex: 1 1 100%;

      @include respond-down(M) {
        margin-bottom: 24px;
        display: block;
      }
    }

    .tags {
      @include respond-down(M) {
        margin-bottom: 12px;
      }

      .tag {
        color: #909090;
        border-color: #909090;
        padding: 0 10px;
        font-size: 14px;
      }
    }
  }

  .h1 {
    font-size: 32px;
    line-height: 1.3em;
    margin-bottom: 8px;
    font-weight: 700;

    @include respond-down(M) {
      font-size: 24px;
    }
  }
  .subtitle {
    color: #909090;
    font-size: 24px;
    margin-bottom: 24px;
    line-height: 1.3em;
    font-weight: 500;

    @include respond-down(M) {
      font-size: 14px;
    }
  }
  .post-body {
    font-size: 16px;
    line-height: 1.3em;
    padding-bottom: 130px;
    font-weight: 500;

    @include respond-down(M) {
      font-size: 14px;
      line-height: 1.4em;
      padding-bottom: 60px;
    }
    &.policy {
      padding-bottom: 0;
    }
  }
}
