.main-banner {
  position: relative;
  padding-bottom: 54%;

  @include respond-down(M) {
    padding-bottom: 0;
    height: 348px;
  }

  .main-banner-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: #222;
    background: url("/static/images/__main_image.jpg") no-repeat center center;
    background-size: cover;

    .txt {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      background: linear-gradient(143.45deg, rgba(0, 0, 0, 0.4) 21.29%, rgba(0, 0, 0, 0.04) 61.25%);

      @include respond-down(M) {
        //align-items: flex-start;
        padding-top: 70px;
        padding-right: 20px;
      }

      &:after {
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        content: "";
        height: 92px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.167046) 30%, rgba(0, 0, 0, 0.6) 100%);
      }

      h1 {
        letter-spacing: 0.02em;
        font-size: 48px;
        line-height: 1.15em;
        color: #fff;
        margin-bottom: 1em;
        font-weight: 700;
        display: block;
        max-width: 50%;

        @include respond-down(L) {
          //  font-size: 50px;
        }
        @include respond-down(M) {
          font-size: 36px;
          max-width: 80%;
        }
        @include respond-down(M) {
          font-size: 32px;
          max-width: 100%;
          margin-bottom: 80px;
        }
      }
    }
  }
}

.accept-tab {
  position: fixed;
  bottom: 0;
  right: 10%;
  left: 10%;
  padding: 40px 102px;
  border-radius: 28px 28px 0px 0px;
  background: #fff;
  font-weight: 500;
  z-index: 300;
  line-height: 1.3em;
  bottom: -300px;
  transition: bottom 0.3s ease;

  @include respond-down(M) {
    border-radius: 0;
    padding: 24px 16px;
    font-size: 14px;
    left: 0;
    right: 0;
  }

  &.show {
    bottom: 0;
  }

  p {
    margin-bottom: 18px;
  }

  a {
    text-decoration: underline;
  }

  .btn {
    margin-left: auto;

    @include respond-down(M) {
      width: 100%;
    }
  }
}

.flex {
  display: flex;
  align-items: flex-start;

  @include respond-down(M) {
    flex-direction: column;
  }

  .left {
    flex: 0 0 416px;

    @include respond-down(M) {
      flex: 1 1 100%;
    }
  }
  .right {
    flex: 1 1 100%;
  }
}

.block-part {
  padding: 90px 0;

  @include respond-down(M) {
    padding: 24px 0;
  }
}

.prefix {
  display: flex;
  align-items: center;

  @include respond-down(M) {
    margin-bottom: 12px;
    align-items: flex-start;
  }

  &.padding {
    padding-left: 9%;

    @include respond-down(M) {
      padding-left: 0%;
    }
  }

  &.B svg {
    transform: rotate(-90deg);
  }
  &.T svg {
    transform: rotate(90deg);
  }
  &.R svg {
    transform: rotate(180deg);
  }
  &.L svg {
    transform: rotate(0deg);
  }

  svg {
    height: 26px;
    margin-right: 14px;
    flex: 0 0 auto;

    @include respond-down(M) {
      height: 20px;
    }

    .center {
      fill: #ee3927;
    }
  }

  h2 {
    position: relative;
    z-index: 10;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #ee3927;

    @include respond-down(M) {
      font-size: 18px;
      margin-top: -5px;
    }

    .sub {
      color: #3d3d3d;
    }
  }
}

.arrows {
  display: flex;
  margin: -7px;

  .round-arrow {
    width: 46px;
    height: 46px;
    border-radius: 46px;
    background: #ee3927;
    cursor: pointer;
    transition: background 0.3s ease;
    margin: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-down(M) {
      width: 32px;
      height: 32px;
      margin: 3px;

      svg {
        width: 14px;
      }
    }

    &:hover {
      background: #cc1200;
    }
  }
}
.index {
  .part--clients {
    padding-bottom: 0;
  }
  .gray-segment {
    margin-bottom: 0;
  }
}

.gray-segment {
  background: linear-gradient(87deg, #fff 6.43%, #e0e0e0 87.89%);
  position: relative;
  overflow: hidden;
  margin-bottom: 140px;
  padding-bottom: 80px;

  @include respond-down(M) {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  &.career {
    @include respond-down(M) {
      padding-bottom: 60px;
    }
  }

  &.bubble {
    .container {
      z-index: 20;
      position: relative;
    }
    &:after {
      z-index: 1;
      content: "";
      width: 900px;
      height: 900px;
      background: no-repeat center center;
      background-image: url("/static/images/ellipse.png");

      transform: rotate(-33.039deg);
      flex-shrink: 0;

      position: absolute;
      top: 400px;
      right: 8%;
      @include respond-down(M) {
        //display: none;
        //padding-bottom: 60px;
      }
    }
  }
}

.files-pdf {
  margin-bottom: 300px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  padding-top: 30px;

  @include respond-down(M) {
    margin-bottom: 80px;
    gap: 20px;
  }

  .file-pdf {
    display: flex;
    width: 300px;
    align-items: flex-start;

    .filename {
      transition: color 0.15s ease;
      margin-bottom: 10px;
      display: block;
    }

    &:hover {
      .filename {
        color: #ee3927;
      }
    }

    svg {
      width: 50px;
      margin-right: 14px;
      flex: 0 0 50px;
    }

    .right {
      padding-top: 10px;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.3em;

      .filesize {
        color: #777;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
  }
}

.white-page {
  position: relative;
  padding-top: 250px;

  @include respond-down(M) {
    padding-top: 70px;
  }

  .form-block {
    .svg-wrapper {
      &::after {
        display: none;
      }
    }
  }

  .prefix {
    margin-bottom: 30px;

    @include respond-down(M) {
      margin-bottom: 14px;
    }
  }
}

.part--clients {
  overflow: hidden;
  padding-bottom: 80px;

  @include respond-down(M) {
    padding-bottom: 36px;
  }

  .prefix {
    @include respond-down(M) {
      padding-bottom: 24px;
    }
  }

  .swipe-clients {
    overflow: hidden;
    padding-top: 80px;
    margin-bottom: 10px;

    @include respond-down(M) {
      padding-top: 0;
      margin-bottom: -10px;
    }

    .swipe-wrapper {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: hidden;

      .line {
        filter: grayscale(1);
        position: relative;
        display: flex;
        animation: offset 60s linear infinite;

        &.l1 {
          animation-delay: 0s;
        }
        &.l2 {
          animation-delay: -20s;
        }
        &.l3 {
          animation-delay: -30s;
        }
      }
      @keyframes offset {
        100% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-50%);
        }
      }

      .item {
        width: 220px;
        height: 90px;
        position: relative;
        margin-right: 30px;
        margin-bottom: 50px;
        background: no-repeat center center;
        background-size: 35%;
        display: inline-block;
        cursor: default;
        position: relative;
        transition: opacity 0.15s linear;
        //border: 1px solid #e3e3e3;
        border-radius: 8px;

        &::after {
          border-radius: 8px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          content: "";
          z-index: -1;
          background: linear-gradient(87deg, #fff 6.43%, #e0e0e0 87.89%);
        }

        @include respond-down(L) {
          width: 180px;
          height: 80px;
          margin-bottom: 20px;
        }
        @include respond-down(M) {
          width: 180px;
        }
        @include respond-down(S) {
          margin-right: 10px;
          margin-bottom: 20px;
          width: 120px;
          height: 50px;
        }

        &:last-child {
          margin-right: 0;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.roic {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 28px;
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 70px;
  height: 70px;
  background-color: #f4f4f4;

  @include respond-down(M) {
    width: 50px;
    height: 50px;
    border-radius: 16px;
  }

  .num {
    left: 24px;
    top: 24px;
    bottom: auto;
    right: auto;
    color: #fff;
    text-shadow:
      #cc1200 0 0 6px,
      #cc1200 0 0 2px,
      #cc1200 0 0 2px,
      #cc1200 0 0 2px;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }

  svg {
    width: 40px;
  }
}

.job-list-empty {
  line-height: 1.3em;
  font-size: 24px;
  margin-bottom: 40px;
  max-width: 800px;

  @include respond-down(M) {
    font-size: 18px;
    margin-bottom: 5px;
  }
}

.job-list-block {
  position: relative;
  z-index: 10;

  .z-top {
    z-index: 30 !important;
    position: relative;
  }

  &.homepage {
    padding-top: 140px;
    //padding-bottom: 60px;
    margin-bottom: 0;

    @include respond-down(M) {
      padding-top: 40px;
    }
  }
  &.transport {
    .dir-title {
      flex: 0 0 30%;
      padding-right: 30px;
    }
    .job-list {
      margin-top: -12px;
      flex: 1 1 100%;
      max-width: 999px;
      padding-right: 10%;
    }
  }

  .job-list {
    padding-left: 30px;
    margin-top: -24px;
    margin-bottom: 140px;
    max-width: 700px;

    @include respond-down(M) {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 40px;
    }

    .job-item {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: block;
      padding: 14px 0;
      border-bottom: 1px solid #d9d9d9;
      padding-right: 100px;
      transition: color 0.15s ease;
      position: relative;

      @include respond-down(M) {
        font-size: 14px;
      }

      svg {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;

        path {
          stroke: #ee3927 !important;
        }
      }

      &:hover {
        color: #ee3927 !important;
      }
    }

    .job-list-items {
      margin-bottom: 30px;

      @include respond-down(M) {
        margin-bottom: 24px;
      }
    }

    .btn-transparent {
      display: inline-flex;
      padding: 8px 24px;
      height: 38px;
    }
  }
  .j-links {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;

    @include respond-down(M) {
      flex-direction: column;
      row-gap: 16px;
    }
  }

  .hh-link {
    padding-left: 40px !important;
    position: relative;

    @include respond-down(M) {
      width: 100%;
    }

    svg {
      position: absolute;
      width: 16px;
      left: 20px;
      display: inline;
      path {
        stroke: #3d3d3d;
        transition: stroke 0.15s ease;
      }
    }

    &:hover {
      svg path {
        stroke: #fff;
      }
    }
  }
}

.btn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  background: #ee3927;
  //min-width: 176px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 38px;
  cursor: pointer;
  border: 1px solid #ee3927;
  transition:
    border 0.14s ease,
    background 0.14s ease,
    color 0.14s ease;
  font-family: "Montserrat", sans-serif;
  padding-left: 24px;
  padding-right: 24px;

  &.btn-transparent {
    background: transparent;
    color: #ee3927;
    //border: 1px solid #ee3927;
  }

  &.btn-gray {
    background: transparent;
    border-color: #909090;
    color: #3d3d3d;

    &:hover {
      background: #3d3d3d;
      border: 1px solid #3d3d3d;
      color: #fff;
      svg path {
        stroke: #fff;
      }
    }
  }

  &.btn-add {
    height: 38px;
    margin: 24px 0 0 0;
    display: inline-block;

    @include respond-down(M) {
      width: 100%;
    }

    label {
      font-size: 14px;
      font-weight: 500;
    }
    span {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    input {
      display: none;
    }
    svg {
      margin-left: 10px;
    }
  }

  &:hover {
    background: #cc1200;
    border: 1px solid #cc1200;
    color: #fff;
  }

  &:active {
    background: #900d00;
    border: 1px solid #900d00;
    color: #fff;
  }

  &.disabled {
    background: #bcbcbc !important;
    border-color: #bcbcbc !important;
    cursor: default;
    color: #848484;
  }
}

.svg-wrapper {
  position: relative;

  &::after {
    content: "";
    transform: rotate(-33.039deg);
    flex-shrink: 0;
    position: absolute;
    left: -30px;
    top: -470px;
    width: 900px;
    height: 900px;
    background: no-repeat center center;
    background-image: url("/static/images/ellipse.png");

    // display: none;

    @include respond-down(M) {
      left: -360px;
      top: 590px;
    }
  }

  svg.partof {
    position: absolute;
    top: -180px;
    width: 430px;
    left: 64px;

    @include respond-down(M) {
      display: none;
    }
  }

  svg.form-team {
    position: absolute;
    top: -200px;
    width: 430px;
    left: 64px;

    @include respond-down(M) {
      display: none;
    }
  }

  svg.prof {
    position: absolute;
    top: -160px;
    left: 140px;

    @include respond-down(M) {
      display: none;
    }
  }

  svg.people {
    position: absolute;
    top: -100px;
    left: 140px;
  }
}

.form-block {
  .form-round {
    border-radius: 28px;
    background: #ececec;
    padding: 70px 100px;
    position: relative;
    z-index: 10;
    overflow: hidden;

    @include respond-down(M) {
      padding: 30px 16px;
    }

    .flex {
      .left {
        @include respond-down(M) {
          width: 100%;
        }

        svg.prof {
          position: absolute;
          width: 300px;
          height: 300px;
          left: 100px;
          bottom: -30px;

          @include respond-down(M) {
            display: none;
          }
        }
      }
    }

    .prefix {
      margin-bottom: 40px;

      @include respond-down(M) {
        margin-bottom: 20px;
      }
      svg {
        transform: rotate(90deg);
      }
    }

    .form {
      margin-top: -14px;
    }
  }

  .checkbox-wrapper {
    color: #909090;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @include respond-down(M) {
      margin-bottom: 30px;
      font-size: 14px;
      align-items: flex-start;
    }

    a {
      text-decoration: underline;
      transition: color 0.15s ease;
      &:hover {
        color: #cc1200;
      }
    }

    [data-checkbox] {
      cursor: pointer;
      user-select: none;
    }

    .checkbox {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      border: 1px solid #909090;
      border-radius: 5px;
      display: inline-block;
      display: flex;
      align-items: center;
      flex: 0 0 16px;
      justify-content: center;

      .inside {
        opacity: 0;
        transform: scale(0.1);
        width: 10px;
        transition:
          transform 0.15s ease,
          opacity 0.15s ease;
      }

      &.check {
        .inside {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  .btn-send {
    margin-left: auto;

    @include respond-down(M) {
      margin: 0;
      width: 100%;
    }
  }
}

.btn-select {
  position: relative;
  z-index: 20;
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
  margin-right: 20px;

  @include respond-down(M) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .c-value {
    user-select: none;

    svg {
      position: absolute;
      right: 16px;
      transition: transform 0.15s ease;
    }
  }

  .c-value,
  .item {
    padding: 0 16px;
    text-align: left;
    height: 38px;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .c-values {
    position: absolute;
    width: 100%;
    top: 38px;
    display: none;
    background: #fff;

    .item {
      color: #3d3d3d;
      width: 100%;
      transition: background 0.2s ease;
      border-left: 1px solid #909090;
      border-right: 1px solid #909090;

      &:first-child {
        border-top: 1px solid #909090;
        border-radius: 5px 5px 0 0;
      }
      &:last-child {
        border-bottom: 1px solid #909090;
        border-radius: 0 0 5px 5px;
      }

      &:hover {
        border-color: #3d3d3d;
        background: #3d3d3d;
        color: #fff;
      }
    }
  }
  &.visible {
    .c-values {
      display: block;
    }

    .c-value svg {
      transform: rotate(180deg);
    }
  }
}

.form-control {
  position: relative;

  input {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    display: block;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    padding: 14px 0;
    color: #3d3d3d; // #909090
    width: 100%;
    background: transparent;
    font-family: "Montserrat", sans-serif;

    &.error {
      &::placeholder {
        color: #ee3927;
      }
    }

    @include respond-down(L) {
      height: 38px;
    }
  }

  .required.errors {
    position: fixed;
    margin-top: 8px;
    font-size: 14px;
    color: red;
  }
}

.automate-block {
  position: relative;
  height: auto;
  padding-left: 10%;

  @include respond-down(M) {
    padding-left: 0;
    margin-bottom: 60px;
  }

  .flex {
    padding-top: 30px;
    padding-bottom: 132px;

    @include respond-down(M) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .text-alerts {
      position: relative;
      text-align: left;
      flex: 1 1 100%;

      @include respond-down(M) {
        padding-bottom: 16px;
        padding-right: 30px;
      }
    }
    .desc {
      flex: 0 0 290px;
      padding-left: 16px;
      line-height: 1.3em;

      @include respond-down(M) {
        padding-left: 0;
        font-size: 14px;
        padding-right: 30px;
        flex: 1 1 auto;
      }
      b {
        font-weight: 700;
      }
    }
  }
}

.container.white-slider-wrapper {
  @include respond-down(M) {
    padding: 0;
  }
}

.white-slider {
  border-radius: 28px;
  border: 1px solid #ececec;
  background: #fff;
  //padding-top: 10px;
  padding-bottom: 50px;
  margin-bottom: 80px;

  @include respond-down(M) {
    border-radius: 0;
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .i-item {
    height: 250px;
    display: flex !important;
    align-items: center;
    @include respond-down(M) {
      height: 286px;
    }

    p {
      text-align: center;
      padding: 0 20px;
      width: 100%;
      //display: flex !important;
      //align-items: center;
      text-align: center;
    }
  }

  .slick-list {
    &:before,
    &:after {
      content: "";
      z-index: 50;
      position: absolute;
      height: 250px;
      width: 30px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #fff 100%);
      right: 0;
      top: 30px;

      @include respond-down(M) {
        display: none;
      }
    }
    &:before {
      background: linear-gradient(90deg, #fff 0%, rgba(0, 0, 0, 0) 100%);
      right: auto;
      left: 0;
    }
  }

  .slick-slide {
    color: #3d3d3d;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3em;
    opacity: 0.5;
    transition:
      opacity 0.15s ease,
      margin-top 0.25s ease;

    @include respond-down(M) {
      opacity: 0;
    }

    &.slick-center {
      opacity: 1;
      font-weight: bold;
      margin-top: -40px;

      @include respond-down(M) {
        margin-top: 0;
      }
    }
  }
}

.slogan-h2 {
  margin: auto;
  position: relative;
  padding-bottom: 80px;

  @include respond-down(M) {
    padding-bottom: 0;
  }

  .pl {
    display: flex;
    align-items: center;
    position: relative;
    height: 480px;

    @include respond-down(M) {
      height: 380px;
      padding: 0;
      justify-content: center;
    }
  }

  .text-alerts {
    max-width: 900px;

    @include respond-down(M) {
      max-width: 300px;

      span {
        display: block;
      }
      b.sp-1 {
        padding-left: 40px;
        display: inline-block;
      }
      b.sp-2 {
        padding-left: 20px;
        display: inline-block;
      }
    }
  }

  svg {
    position: absolute;
    z-index: 1;
    &.bg-ok {
      width: 48px;
      right: 0;
      top: 0;

      @include respond-down(M) {
        width: 28px;
        top: 50px;
      }
    }
    &.bg-ok2 {
      width: 48px;
      left: 544px;
      bottom: 53px;

      @include respond-down(M) {
        width: 28px;
        right: 0;
        left: auto;
      }
    }
    &.bg {
      width: 1360px;
      left: 50%;
      top: 0;
      margin-left: -680px;

      @include respond-down(M) {
        width: 860px;
        left: 132%;
      }
    }
  }
}

.slides {
  padding-bottom: 90px;

  @include respond-down(M) {
    padding-bottom: 80px;
  }

  .simg {
    background: no-repeat center center;
    background-size: cover;
  }
  .TPL {
    display: none;
  }
  .radius {
    border-radius: 16px;
    background: #ececec;
    overflow: hidden;
    @include respond-down(M) {
      border-radius: 8px;
    }
  }
  .con {
    position: relative;
    i > svg {
      position: absolute;
      path {
        stroke-width: 0.8;
      }
    }
    &.c1 svg {
      width: 460px;
      height: 400px;
      transform: scale(-1, 1);
      top: -110px;
    }
    &.c5,
    &.c6 {
      background: #fff;
      svg {
        transform: scale(-1, 1);
        width: 140px;
        left: -50px;
        top: 7px;
        path {
          stroke: #000;
        }
      }
    }
    &.c6 {
      background: #d9d9d9;
      svg {
        left: 23px;
        top: -50px;
        transform: scale(-1, 1);
      }
    }
  }
  .about-link {
    cursor: pointer;
    background: #ee3927;
    color: #fff;
    display: flex;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: background 0.15s ease;

    i svg {
      top: -70px;
      left: 120px;
      transform: rotate(-40deg);
      width: 260px;
      opacity: 1;
      transition:
        transform 0.35s ease,
        opacity 0.15s ease;
      @include respond-down(M) {
        display: none;
      }

      path {
        stroke: #fff;
      }
    }
    span {
      position: relative;
      z-index: 20;
      display: flex;
      align-items: center;

      @include respond-down(M) {
        text-decoration: underline;
        font-size: 14px;
        margin-top: -3px;
      }

      svg {
        margin-left: 10px;
        @include respond-down(M) {
          display: none;
        }
      }
    }
    svg.RFL {
      position: relative;
      width: 100px;
      fill: #fff;

      @include respond-down(M) {
        width: 60px;
      }
    }

    &:hover {
      background: #900d00;

      i svg {
        transform: rotate(-40deg) scale(1.3);
        opacity: 0.5;
      }
    }
  }
  .s5 {
    padding-bottom: 41%;
    //height: 240px;
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 18px;

    .div1 {
      grid-column: span 6 / span 6;
      grid-row: span 3 / span 3;
    }

    .div2 {
      grid-column: span 2 / span 2;
      grid-row: span 3 / span 3;
      grid-column-start: 1;
      grid-row-start: 4;
    }

    .div3 {
      grid-column: span 4 / span 4;
      grid-row: span 3 / span 3;
      grid-column-start: 3;
      grid-row-start: 4;
    }

    .div4 {
      grid-column: span 3 / span 3;
      grid-row: span 6 / span 6;
      grid-column-start: 7;
      grid-row-start: 1;
    }

    .div5 {
      grid-column-start: 10;
      grid-row-start: 1;
    }

    .div6 {
      grid-column-start: 10;
      grid-row-start: 2;
    }

    .div7 {
      grid-column: span 2 / span 2;
      grid-row: span 2 / span 2;
      grid-column-start: 11;
      grid-row-start: 1;
    }

    .div8 {
      grid-column: span 3 / span 3;
      grid-row: span 2 / span 2;
      grid-column-start: 10;
      grid-row-start: 3;
    }

    .div9 {
      grid-column: span 3 / span 3;
      grid-row: span 2 / span 2;
      grid-column-start: 10;
      grid-row-start: 5;
    }

    @include respond-down(M) {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(12, 1fr);
      gap: 16px;

      .div1 {
        grid-column: span 6 / span 6;
        grid-row: span 3 / span 3;
      }

      .div2 {
        grid-column: span 3 / span 3;
        grid-row: span 3 / span 3;
        grid-row-start: 4;
      }

      .div3 {
        grid-column: span 3 / span 3;
        grid-row: span 3 / span 3;
        grid-column-start: 4;
        grid-row-start: 4;
      }

      .div4 {
        grid-column: span 3 / span 3;
        grid-row: span 6 / span 6;
        grid-row-start: 7;
      }

      .div5 {
        grid-column-start: 4;
        grid-row-start: 7;
      }

      .div6 {
        grid-column-start: 4;
        grid-row-start: 8;
      }

      .div7 {
        grid-column: span 2 / span 2;
        grid-row: span 2 / span 2;
        grid-column-start: 5;
        grid-row-start: 7;
      }

      .div8 {
        grid-column: span 3 / span 3;
        grid-row: span 2 / span 2;
        grid-column-start: 4;
        grid-row-start: 9;
      }

      .div9 {
        grid-column: span 3 / span 3;
        grid-row: span 2 / span 2;
        grid-column-start: 4;
        grid-row-start: 11;
      }
    }
  }
}

.slides.career {
  padding-bottom: 140px;

  @include respond-down(M) {
    padding-bottom: 60px;
  }

  .about-link {
    cursor: default;

    .RFL {
      width: 162px;
      @include respond-down(M) {
        width: 70px;
      }
    }
  }
  .con.c1 {
    svg.prof-2 {
      height: auto;
      width: 110px;
      transform: scale(1, 1);
      top: 2px;

      @include respond-down(M) {
        width: 60px;
        left: 10px;
        top: 10px;
      }
    }
    svg.prof-1 {
      width: 140px;
      transform: scale(1, 1);
      left: 60px;
      bottom: 0;
      top: auto;
      height: auto;

      @include respond-down(M) {
        width: 80px;
        left: 70px;
      }
    }
  }
  .con.c5 {
    svg.prof-1 {
      height: auto;
      width: 50px;
      transform: scale(1, 1);
      top: 12px;
      left: 20px;
      @include respond-down(M) {
        left: 6px;
        width: 30px;
        top: 3px;
      }
    }
  }
  .con.c6 {
    svg.prof-2 {
      width: 50px;
      transform: scale(1, 1);
      left: 20px;
      top: 12px;
      height: auto;

      @include respond-down(M) {
        left: 11px;
        width: 20px;
        top: 3px;
      }
    }
  }
}

.con-pad {
  margin: 0 9% 0 9%;

  @include respond-down(M) {
    margin: 0 16px;
  }
}

.go-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 200;
  min-width: 0;
  padding-right: 10px;
  padding-left: 10px;
  opacity: 0;
  transition: all 0.3s ease;

  &.visible {
    opacity: 1;
  }
}

.career-page {
  .career {
    margin-bottom: 0;

    .job-list-block .job-list {
      margin-bottom: 0;
    }
  }
  .form-block {
    margin-bottom: 140px;
    @include respond-down(M) {
      margin-bottom: 40px;
    }
  }
  .svg-wrapper {
    @include respond-down(M) {
      display: none;
    }
  }
}

.E404 {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;

  &:after {
    top: 10% !important;
  }

  .btn {
    margin-top: 150px;
    z-index: 20;
    position: relative;

    /*
    @include respond-down(M) {
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 24px;
    }
    */
  }

  svg {
    position: absolute;
    z-index: 10;

    &.ro {
      width: 1966px;

      @include respond-down(M) {
      }
    }
  }
}
